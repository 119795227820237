import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { SlideProductContainer } from "../styles/Home";
import SlideProducts from "../components/Slider/Products";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader";

import { useProductRequests } from "../helpers/ProductRequests";

import CartItems from "../components/Cart/CartItems";
import { Login } from "../styles/Auth";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";

import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { useAuthRequests } from "../helpers/AuthRequests";
export default function Basket(props) {
  const history=useHistory()
  const { getDiscover, getMainProducts } = useProductRequests();

  const {changePasswordRequest} = useAuthRequests()

  const { basket } = useSelector((state) => state.cart);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [discover, setDiscover] = useState([]);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  useLayoutEffect(() => {
    if (discover.length === 0) {
      getMainProducts({ type: 1 })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {});
    }
  }, []);
  const changePassword=(e)=>{
    e.preventDefault();
    setLoading(true)
    if(newPassword!=confirmPassword){
      setError("Yeni şifreler aynı olmalıdır.")
      setLoading(false)
    }else{
      changePasswordRequest({ oldPassword:oldPassword, newPassword:newPassword })
        .then((response) => {
          setLoading(false);
          setSuccess(response.data.message);
          setOldPassword('')
          setNewPassword('')
          setConfirmPassword('')
          setTimeout(() => {
            history.push("/cikis-yap");
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.response.data.message);
        });
    }
  }
  return (
    <>
      <Header />

      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 100,
            marginBottom: 100,
          }}
        >
          <form
            style={{
              width: 700,
              background: "#d8eaff",
              borderRadius: 50,
              padding: 100,
            }}
            onSubmit={changePassword}
          >
            <div
              style={{
                color: "#000",
                width: 500,
                fontSize: 25,
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <div style={{ width: 100 }}>
                <FontAwesomeIcon style={{ marginRight: 50 }} icon={faKey} />
              </div>
              <div
                style={{ width: 400, textAlign: "right", fontWeight: "bold" }}
              >
                Şifre Değiştirme Formu
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "& > :not(style)": { m: 1 },
              }}
            >
              <TextField
                autoComplete="on"
                label="Eski Şifre"
                autocomplete="on"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                variant="filled"
                type="password"
                style={{ marginTop: 11, width: 500 }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "& > :not(style)": { m: 1 },
              }}
            >
              <TextField
                autoComplete="on"
                label="Yeni Şifre"
                autocomplete="on"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="filled"
                type="password"
                style={{ marginTop: 11, width: 500 }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "& > :not(style)": { m: 1 },
              }}
            >
              <TextField
                autoComplete="on"
                label="Tekrar Yeni Şifre"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autocomplete="on"
                variant="filled"
                type="password"
                style={{ marginTop: 11, width: 500 }}
              />
            </Box>

            {error != "" && (
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "red",
                  alignItems: "center",
                  "& > :not(style)": { m: 1 },
                }}
              >
                {" "}
                <div className="error-area">{error}</div>{" "}
              </Box>
            )}
            {success != "" && (
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "green",
                  alignItems: "center",
                  "& > :not(style)": { m: 1 },
                }}
              >
                {" "}
                <div className="success-area">{success}</div>{" "}
              </Box>
            )}
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              type="submit"
              style={{ marginTop: 10, width: 480, marginLeft: 10 }}
              variant="contained"
              className="authBtn"
            >
              Şifremi Değiştir
            </LoadingButton>
          </form>
        </div>

        <SlideProductContainer>
          <h1
            style={{
              color: "rgba(255,10,0)",
              paddingTop: 40,
              fontSize: 30,
              marginBottom: 8,
              fontWeight: "bold",
            }}
            // onClick={() => history.push("/yeni-urunler")}
          >
            Kampanyalı Ürünler
          </h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
