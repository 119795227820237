import React from 'react'
import {AuthPage} from '../../styles/Auth'
import { Link } from 'react-router-dom';
import { Tab } from '@headlessui/react'
import RegisterForm from '../../components/Forms/RegisterForm'
import LoginForm from '../../components/Forms/LoginForm'
import ForgotForm from '../../components/Forms/ForgotForm';

import Footer from '../../components/Navigation/Footer';

import CookieConsent, { Cookies } from "react-cookie-consent";
import mainlogowhite from '../../mainlogowhite.png'
export default function Contact() {

  return (
    <AuthPage>
      {/* <CookieConsent
        location="bottom"
        buttonText="Tümünü Kabul Et"
        cookieName="dehasoft"
        style={{ background: "#DB2C1D",minHeight:'10vh',alignItems:'center' }}
        buttonStyle={{ color: "#000", fontSize: "15px" }}
        expires={250}
      >
        Bu site deneyimlerinizi kişiselleştirmek amacıyla KVKK ve GDPR uyarınca çerez(cookie) kullanmaktadır. Bu konu hakkında detaylı bilgi almak için çerez politikası ve aydınlatma metni sayfalarını gözden geçirebilirsiniz. Sitemizi kullanarak, çerezleri kullanmamızı kabul edersiniz.{" "}
        <span style={{ fontSize: "10px" }}> &copy; Dehasoft 2023</span>
      </CookieConsent> */}
      <Link to="/" className="logo">
        <img
          onContextMenu={() => false}
          height={530}
          style={{marginTop:-160}}
          src={mainlogowhite}
        />
      </Link>

      <LoginForm />

      {/* <Footer/> */}
    </AuthPage>
  );
}
