import React,{useState,useEffect,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer, HomeList} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'

import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import { useHistory } from 'react-router-dom'

import {Helmet} from "react-helmet";
import BigCategories from '../components/Navigation/BigCategories'

export default function Home(props) {
  
  const { getProducts, getDiscover, getBrands, getMainProducts } =useProductRequests();
  
  const {slider}=useSelector(state=>state.site)

  const [open, setOpen] = useState(false);

  const history=useHistory()
  
  const [discover,setDiscover]=useState([])
  const [products, setProducts] = useState([]);
  const [mainProducts, setMainProducts] = useState([]);
  const [brands,setBrands]=useState([])

  useLayoutEffect(() => {
    
    if (mainProducts.length === 0) {
      getMainProducts({ type: 0 })
        .then((response) => {
          setMainProducts(response.data.data.items);
        })
        .catch((error) => {

        });

      getMainProducts({ type: 1 })
        .then((response) => {
          setProducts(response.data.data.items);
        })
        .catch((error) => {});
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Ana Sayfa</title>
      </Helmet>

      <Header homepage={true} />

      <HomeList open={open}>
        <BigCategories open={open} setOpen={setOpen} />

        <div className="sliders" >
          <SlideProductContainer width={"100%"} left={"0%"}>
            {/* <h1
              style={{ color: "#000", paddingTop: 40 }}
              // onClick={() => history.push("/yeni-urunler")}
            >
              Ana Sayfa
            </h1> */}
            {mainProducts != null &&
            mainProducts != undefined &&
            mainProducts.length > 0 ? (
              <SlideProducts items={mainProducts} mainProducts={true} />
            ) : (
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "20px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
              </div>
            )}
          </SlideProductContainer>

          <SlideProductContainer width={"100%"} left={"0%"}>
            <h1
              style={{ color: "rgba(255,10,0)", paddingTop: 40,fontSize:33,marginBottom:28,marginTop:-70,fontWeight:'bold' }}
              // onClick={() => history.push("/yeni-urunler")}
            >
              Kampanyalı Ürünler
            </h1>
            {products != null &&
            products != undefined &&
            products.length > 0 ? (
              <SlideProducts items={products} />
            ) : (
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "20px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
              </div>
            )}
          </SlideProductContainer>
        </div>
      </HomeList>

      <BottomNav />

      <Footer />
    </>
  );
}
