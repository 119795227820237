import axios from "axios";

export const useCartRequests = () => {
  const getCart = async () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_GET_CART}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_GET_CART
        }`,
        Authorization: `${localStorage.getItem("sarfnettoken")}`,
      },
    });
  };
  const addCart = async (items) => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_ADD_TO_CART
      }?productId=${items[0].id}&quantity=${items[0].count}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_ADD_TO_CART
        }`,
        Authorization: `${localStorage.getItem("sarfnettoken")}`,
      },
    });
  };
  const deleteCart = async () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_DELETE_CART}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_DELETE_CART
        }`,
        Authorization: `${localStorage.getItem("sarfnettoken")}`,
      },
    });
  };

  const incrementCart = async (id, quantity = 1) => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_INCREMENT_CART
      }?productId=${id}&quantity=${quantity}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_INCREMENT_CART
        }`,
        Authorization: `${localStorage.getItem("sarfnettoken")}`,
      },
    });
  };

  const decrementCart = async (id, quantity = 1) => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_DECREMENT_CART+`?productId=${id}&quantity=${quantity}`
      }`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_INCREMENT_CART
        }`,
        Authorization: `${localStorage.getItem("sarfnettoken")}`,
      },
    });
  };

  const extractionCart = async (id) => {
    return axios({
      method: "get",
      url:
        `${
          process.env.REACT_APP_API + process.env.REACT_APP_EXTRACTION_CART
        }?productId=` + id,
      data: {
        product_id: id,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_EXTRACTION_CART
        }`,
        Authorization: `${localStorage.getItem("sarfnettoken")}`,
      },
    });
  }; //

  //
  return {
    getCart,
    addCart,
    deleteCart,
    incrementCart,
    decrementCart,
    extractionCart,
  };
};
