import React,{useEffect,useState} from 'react'
import { ThemeProvider } from "styled-components";
import {GlobalStyle} from './styles/Global';
import {LightTheme,DarkTheme} from './styles/Theme'
import { Route,Switch} from "react-router-dom";
import {Routes} from './Routes'
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCartRequests } from './helpers/CartRequests'

import { setBasket } from './components/stores/Cart'

import axios from 'axios'

import {useHistory} from 'react-router-dom'

// import ScrollButton from './components/Content/ScrollButton';

import NotFound from './views/NotFound'


function App() {
  
  const history=useHistory()
  const [mainRoutes,setMainRoutes]=useState(null)

  const getMainRoutes=()=>{
    Routes.then(res=>{
      setMainRoutes(res)
        
  })}


  useEffect(()=>{
    if(mainRoutes==null){
      getMainRoutes()
    }
  },[])

  const dispatch=useDispatch()

  const {login}=useSelector(state=>state.auth)

  const {theme}=useSelector(state=>state.site)

  const {basket}=useSelector(state=>state.cart)

  const {getCart} = useCartRequests()

  if(login && basket==null){
    getCart().then(res=>{
      dispatch(setBasket(res.data.data))
    })
  }

  axios.interceptors.response.use((response) => response, (error) => {
    try {
      if(error.response.status==401){
        history.push('/giris-yap')

      }
    } catch (error) {
      
    }
    
    throw error;
  });

  const listRoutes=(routeList)=>{
      return routeList.map((route,index)=>{
                
        return(

          <Route key={`route-${route.path}`} path={route.path} exact={route.exact} component={route.component} />
        )

      })

  }

  return (
      <ThemeProvider theme={theme==='DarkTheme' ? DarkTheme : LightTheme}>
        <GlobalStyle/>
        <ToastContainer/>
        {/* <ScrollButton/> */}

            <Switch>

              {
                mainRoutes!=null ? listRoutes(mainRoutes) : <></>
              }
              
              <Route path="*" render={() => <NotFound/>} />
            </Switch>

          
      </ThemeProvider>
  );
}

export default App;
