import React,{useState,useEffect} from 'react'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"
import {useProductRequests} from '../helpers/ProductRequests'
import { useHistory,useParams } from 'react-router-dom'

import { Sentry } from "react-activity";
import ProductDetail from '../components/Content/ProductDetail';
import { Helmet } from 'react-helmet'

export default function Product(props) {

  const {slug} = useParams()

  
  const {getDiscover,getProduct} = useProductRequests()
  
  const history=useHistory()

  const [product,setProduct] = useState(null)

  const [discover,setDiscover]=useState([])
  const [oldSlug,setOldSlug]=useState(null)
  useEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:Math.floor(Math.random()*15)+Math.floor(Math.random()*20),count:10,sort:"date",sortedBy:Math.floor(Math.random()*10)%2==0 ? "desc":"asc"}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error)
      })

    }

    if((oldSlug==null && product==null) || (oldSlug!=null && slug!=oldSlug)){
      getProduct(slug).then((response)=>{
        setProduct(response.data.data)      
      }).catch((error)=>{
        setProduct('empty')
        console.log("error",error)
      })
      setOldSlug(slug)
      window.scrollTo(0, 0);

    }

  }, [slug])

  

  return (
    <>
      <Header />

      {product == null ? (
        <div className="loader">
          <Helmet>
            <title>Yükleniyor...</title>
          </Helmet>
          <Sentry color={"#19477B"} size={230} />
        </div>
      ) : product === "empty" ? (
        <div className="loader">
          <Helmet>
            <title>Ürün Bulunamadı</title>
          </Helmet>
          <h1>Ürün bulunamadı!</h1>
        </div>
      ) : (
        <ProductDetail data={product} />
      )}

      <div>
        <SlideProductContainer width={"90%"} left={"5%"}>
          <h1 onClick={() => history.push("/kesfet")}>Keşfet</h1>
          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
