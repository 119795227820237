import {
  faBell,
  faEnvelope,
  faMoon,
  faPhone,
  faSearch,
  faShoppingBasket,
  faSun,
  faTags,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header, HeaderTop } from "../../styles/Header";
import Flags from "country-flag-icons/react/3x2";
import Switch from "react-switch";
import { useSelector, useDispatch } from "react-redux";
import { changeTheme } from "../stores/Site";
import { useHistory, useLocation } from "react-router";
import { Menu } from "@headlessui/react";
import Categories from "./Categories";
import BigCategories from "./BigCategories";

import logo from "../../mainlogo.png";
import { Currency } from "react-intl-number-format/dist/index.js";
import { useAuthRequests } from "../../helpers/AuthRequests";
import { newCurrencySet, setCustomer } from "../stores/Auth";
import { useCartRequests } from "../../helpers/CartRequests";
import { setBasket } from "../stores/Cart";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
export default function HeaderComponent(props) {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const { theme } = useSelector((state) => state.site);

  const { login, currency } = useSelector((state) => state.auth);

  const { admin } = useSelector((state) => state.auth);

  const { basket } = useSelector((state) => state.cart);

  const { setCurrency } = useAuthRequests();
  const { getCart } = useCartRequests();

  const [search, setSearch] = useState("");

  const changeCurrency = async (newCurrency) => {
    localStorage.removeItem("sarfnetcampaignscampaign");
    localStorage.removeItem("sarfnetmainproducts0");
    localStorage.removeItem("sarfnetmainproducts1");

    setCurrency(newCurrency).then(async (res) => {
      localStorage.setItem("balance", res.data.data.balance);
      localStorage.setItem("currency", newCurrency);
      localStorage.setItem("cargoCostLimit", res.data.data.cargoCostLimit);
      const set = await dispatch(newCurrencySet(newCurrency));
      const cart = await getCart();
      const update = await dispatch(setBasket(cart.data.data));
      setTimeout(() => {
        window.location.reload(false);
      }, 700);
    });
  };

  return (
    <>
      <HeaderTop>
        <ul>
          {localStorage.getItem("sarfnettoken") != null && (
            <li>
              <button
                onClick={() => changeCurrency("TL")}
                style={{
                  zIndex: 99,
                  padding: 10,
                  background: currency === "TL" ? "#2ecc71" : "#fff",
                  color: currency === "TL" ? "#fff" : "#000",
                }}
              >
                ₺ (Türk Lirası)
              </button>
              <button
                style={{
                  zIndex: 99,
                  padding: 10,
                  background: currency !== "TL" ? "#2ecc71" : "#fff",
                  color: currency !== "TL" ? "#fff" : "#000",
                }}
                onClick={() => changeCurrency("$")}
              >
                $ ( ABD Doları )
              </button>
            </li>
          )}
        </ul>
        <ul>
          <li
            style={{
              marginRight: 30,
              fontSize: 13,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              style={{ fontSize: 22, marginRight: 15 }}
            />{" "}
            +90 555 106 82 06
          </li>
          <li
            style={{
              marginRight: 30,
              fontSize: 13,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faPhone}
              style={{ fontSize: 22, marginRight: 15 }}
            />{" "}
            +90 216 450 56 65
          </li>
          <li
            style={{
              marginRight: 30,
              fontSize: 13,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ fontSize: 22, marginRight: 15 }}
            />{" "}
            info@ucuzsarf.com
          </li>
        </ul>
      </HeaderTop>
      <Header>
        <div className={"logo " + (location.pathname != "/" ? "box-none" : "")}>
          <Link to="/">
            <img onContextMenu={() => false} src={logo} alt="Logo-Resim" />
          </Link>
        </div>
        <div
          className={
            "search-box " + (location.pathname == "/" ? "box-none" : "")
          }
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                history.push("/search/" + search);
              }
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="ürün, kategori veya marka ara"
          />
          <button onClick={() => history.push("/search/" + search)}>
            {" "}
            ARA{" "}
          </button>
        </div>
        <div
          className={"buttons " + (location.pathname != "/" ? "box-none" : "")}
        >
          {login == false ? (
            <>
              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  {" "}
                  <FontAwesomeIcon icon={faUser} /> Giriş Yap
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  <Menu.Item>
                    <Link to="/giris-yap">Giriş Yap</Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                      <Link to="/kayit-ol" >Kayıt Ol</Link>
                    </Menu.Item> */}
                </Menu.Items>
              </Menu>
            </>
          ) : (
            <>
              {/* <div
                className={
                  "balance " +
                  (1 * localStorage.getItem("balance") > 0 ? "plus" : "times")
                }
              >
                <span>Bakiyeniz</span>
                <Currency>{1 * localStorage.getItem("balance")}</Currency>
              </div> */}
              {/* <Link to="/"><FontAwesomeIcon icon={faTags} /></Link> */}
              {/* <Link to="/"><FontAwesomeIcon icon={faBell} /></Link> */}
              <button
                onClick={() => history.push("/sepetim")}
                className="closer"
              >
                <div>
                  <FontAwesomeIcon icon={faShoppingBasket} />
                  <span>
                    {basket != null && basket != undefined ? basket.length : ""}
                  </span>
                </div>
                Sepetim
              </button>

              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  {" "}
                  <FontAwesomeIcon icon={faUser} />{" "}
                  <span style={{ display: "flex", flexWrap: "nowrap" }}>
                    Hesap{" "}
                  </span>{" "}
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  {/* <Menu.Item>
                    <Link to="#" className="balance-mobile">
                      Bakiyeniz <br />
                      {1 * localStorage.getItem("balance")}
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item>
                    <Link to="/">
                      {localStorage.getItem("customer") != null ? (
                        <>
                          <marquee>
                            {" Hoş Geldiniz "}
                            
                            {JSON.parse(localStorage.getItem("customer")).name}{" "}
                            {
                              JSON.parse(localStorage.getItem("customer"))
                                .surname
                            }
                          </marquee>
                        </>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <Link to="/tahsilat">Tahsilat</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/hareketlerim">Hareketler</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/faturalar">Faturalar</Link>
                  </Menu.Item> */}
                  <Menu.Item>
                    <Link to="/siparisler">Siparişlerim</Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <Link to="/urun-raporu">Ürün Raporu</Link>
                  </Menu.Item> */}
                  {admin == "true" ? (
                    <Menu.Item>
                      <Link to="/yonetici">Yönetici</Link>
                    </Menu.Item>
                  ) : (
                    <></>
                  )}
                  <Menu.Item>
                    <Link to="/sifre-degistir">Şifre Değiştir</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/cikis-yap">Çıkış Yap</Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </>
          )}
        </div>
      </Header>

      {/* {props.homepage==undefined || props.homepage==null ? <Categories />:<></>} */}
      {props.homepage == undefined || props.homepage == null ? <></> : <></>}
    </>
  );
}
