import React,{useState,useEffect} from 'react'

import {CartContainer} from '../../styles/Cart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTrashAlt, faShoppingCart, faShoppingBasket, faCreditCard, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useCartRequests } from '../../helpers/CartRequests';
import { toast } from 'react-toastify';
import { setBasket } from '../stores/Cart';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ListItem from './Item'
import { useOrderRequests } from '../../helpers/OrderRequests';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import mainLogo from '../../mainlogo.png'
export default function CartItems({cart}) {

  const [total,setTotal]=useState(0)
  const [selectedTab,setSelectedTab]=useState("transfer")
  const [paymentError,setPaymentError]=useState(null)
  const [paymentForm,setPaymentForm]=useState(null)

  const [loading,setLaoding]=useState(false)
  const [bankAccounts,setBankAccounts]=useState(null)
  const [copied,setCopied]=useState(false)

  const {deleteCart}=useCartRequests()
  const {createOrder,getBankAccounts,createPaymentForm}=useOrderRequests()
  const history=useHistory()

  const dispatch=useDispatch()
const iframeRef = React.useRef({
  contentWindow: { document: { body: "null" } },
});

  const getPaymentForm=async()=>{
    setPaymentError(null)
    createPaymentForm({type:2}).then(res=>{
      setPaymentForm(res.data.data.htmlContent);
      
    }).catch(err=>{
      setPaymentError("Beklenmedik bir hata oluştu. Tekrar denemek için aşağıdaki butona tıklayabilirsiniz.")
    })
  }

  useEffect(() => {
    if(selectedTab=="card"){
      getPaymentForm()
    }
  
    
  }, [selectedTab])

  useEffect(() => {
    const messageFunc = (event) => {
      // Güvenlik kontrolü yapmak önemlidir, event.origin ile güvenilir bir kaynaktan geldiğini kontrol edin
      const receivedData = event.data;
      // console.log("receivedData", receivedData);
      if (
        receivedData != null &&
        receivedData != "" &&
        receivedData.length > 5
      ) {
        window.location.href = receivedData;
      }
      //
    };
    window.addEventListener("message", messageFunc);
  
    return () => {
      window.removeEventListener("message", messageFunc);
    }
  }, [])
  
  

  useEffect(() => {
    if(cart!=null && cart!=undefined){
      let totalCount=0
      cart.map((item)=>{
        totalCount+=(item.salePrice*item.quantity)
      })
      setTotal(totalCount)
    }
  }, [cart])

  useEffect(()=>{
    if(bankAccounts==null){
      getBankAccounts().then(res=>{
        setBankAccounts(res.data.data)
      })
    }
  },[bankAccounts])
  
  const removeCart=()=>{
    
    const loader=toast.loading('Sepet siliniyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
    deleteCart().then(response=>{
      dispatch(setBasket(null))
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })
    .catch(error=>{
      toast.update(loader, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })  

  }

  const createNewOrder=()=>{
    if(loading){
      return
    }
    setLaoding(true)
    const loader=toast.loading('Sipariş oluşturuluyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
    createOrder(
      {
        paymentType:selectedTab=="transfer" ? 3 : 1 , //2 kredi kartı , 4 nakit
        orderNote:"Dehasoft",
        products:cart.map(x=>{
          return {productId:x.productId,unit:x.usedUnit,quantity:x.quantity}
        })
      }
    ).then(response=>{
      setLaoding(false);
      history.push({
        pathname: "/odeme-basarili",
        state: {
          paymentType: selectedTab == "transfer" ? 3 : 1,
        },
      });
      removeCart()
      dispatch(setBasket(null))
      
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })
    .catch(error=>{
      setLaoding(false);
      toast.update(loader, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })  
  }

  const goHome=()=>{
    history.push('/')
  }
  
  const handleCopyIban=(iban="IBANBULUNAMADI")=>{
    const loader=toast.loading('IBAN numarası kopyalanıyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
    toast.update(loader, { render: "IBAN numarası başarı ile kopyalandı", type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    setCopied(true)

  }

  const getPaymentButton=()=>{
    if(selectedTab=="transfer"){
      return <button className='payment' onClick={createNewOrder}>Sipariş Oluştur</button>
    }else{
      return
      // return <button className='payment' onClick={()=>alert("asd")}>Ödemeye Geç</button>

    }
  }

  

  return (
    <CartContainer>
      <div className="finally">
        <div className="calculate">
          <div className="head">
            Toplam {cart != null ? cart.length : 0} Ürün
          </div>
          <div className="total">
            {/* <CurrencyFormat lang='tr' value={total.toFixed(2)} displayType={'text'}  thousandSeparator={true}  /> */}
            <Currency>{total}</Currency>
          </div>
          {cart == null || cart.length < 1 ? (
            <button className="payment" onClick={() => goHome()}>
              Alışverişe Devam Et
            </button>
          ) : (
            <>{getPaymentButton()}</>
          )}
        </div>
        <div className="prod-list">
          <h1 style={{ marginTop: 5, marginBottom: 5, fontSize: 27 }}>
            Hesap listesi
          </h1>

          {bankAccounts != null ? (
            bankAccounts.map((x) => {
              return (
                <CopyToClipboard
                  key={x.iban}
                  text={x.iban}
                  onCopy={handleCopyIban}
                >
                  <div className="bank-card">
                    <h1>{x.bankTitle}</h1>
                    <h2>
                      Para Birimi : {x.accountTitle} ({x.accountType})
                    </h2>
                    <h2>Hesap Adı : {x.bankAddress} </h2>
                    <div className="iban">
                      {x.iban}
                      <FontAwesomeIcon icon={faCopy} />
                    </div>
                  </div>
                </CopyToClipboard>
              );
            })
          ) : (
            <></>
          )}
          {/* {cart != null && cart.length > 0 ? (
            cart.map((x) => {
              return (
                <div className="prod-item" key={x.title}>
                  <div className="name">{x.title}</div>
                  <Currency className="price">{x.salePrice}</Currency>
                  <div
                    className="qty"
                    style={{ fontWeight: "bold", fontStyle: "italic" }}
                  >
                    x{x.quantity}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )} */}
        </div>
      </div>
      <div className="list">
        <ul style={{ width: "95%" }}>
          <li className="title">
            {/* <h1>
              {" "}
              <FontAwesomeIcon icon={faCreditCard} /> Ödeme Adımları{" "}
            </h1> */}
            {/* {
              cart!=null && cart.length>0 ?
              <div className='removeAll'>
                <span onClick={()=>{
                  removeCart()
                }}><FontAwesomeIcon icon={faTrashAlt} /></span>
              </div>
              : <></>
            } */}
          </li>
          {cart == null || cart.length < 1 ? (
            <li className="empty-cart">
              <h1>Sepetiniz Boş </h1>
              <button className="payment" onClick={() => goHome()}>
                Alışverişe Devam Et <FontAwesomeIcon icon={faShoppingBasket} />
              </button>
            </li>
          ) : (
            <div className="payment-tabs">
              {/* <div
                onClick={() => setSelectedTab("transfer")}
                className={
                  "payment-tab " + (selectedTab == "transfer" ? " active" : "")
                }
              >
                Havale/Eft
              </div> */}
              {/* <div
                onClick={() => setSelectedTab("card")}
                className={
                  "payment-tab " + (selectedTab == "card" ? " active" : "")
                }
              >
                Kredi Kartı
              </div> */}
            </div>
          )}
          <div
            className="bank-cards"
            style={{ display: selectedTab == "transfer" ? "block" : "none" }}
          >
            <div className="prod-list" style={{ width: "100%" }}>
              <h1 style={{ marginTop:5, marginBottom: 60, fontSize: 27 }}>
                Ürün Listesi
              </h1>
              {cart != null && cart.length > 0 ? (
                cart.map((x, index) => {
                  return (
                    <div
                      className="prod-item"
                      key={x.title.toUpperCase()}
                      style={{
                        marginTop: 20,
                        paddingBottom: 20,
                        display: "flex",
                        borderBottom:
                          (index == cart.length - 1 ? "0" : "2") +
                          "px solid #1111",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }} className="name">
                        {x.title.toUpperCase()}
                      </div>
                      <div style={{ width: 140 }}>
                        <Currency className="price">{x.salePrice}</Currency>
                      </div>
                      <div
                        className="qty"
                        style={{
                          width: 100,
                          fontWeight: "bold",
                        }}
                      >
                        x{x.quantity}
                      </div>
                      <div
                        className="qty"
                        style={{
                          width: 140,
                          fontWeight: "bold",
                        }}
                      >
                        <Currency style={{ width: 140 }}>
                          {x.quantity * x.salePrice}
                        </Currency>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>

          <div
            className="payment-card"
            style={{ display: selectedTab == "card" ? "block" : "none" }}
          >
            {paymentError != null && paymentForm != null ? (
              <div className="paymentError">
                {paymentError}
                <button onClick={() => getPaymentForm()}>Tekrar Dene</button>
              </div>
            ) : (
              <iframe ref={iframeRef} srcDoc={paymentForm}></iframe>
            )}
          </div>
        </ul>
      </div>
    </CartContainer>
  );
}
