import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import {FooterContainer} from '../../styles/Header'
import logo from "../../mainlogowhitesmall.png";
export default function Footer() {
  return (
    <FooterContainer>
      <div className="copyright">
        <img src={logo} height={50} style={{ marginBottom: 10 }} />
        <p className="slogan"> Rampage Bölge Bayi </p>
        <span
          className="slogan"
          style={{
            color: "#fff",
            display: "flex",
            marginTop:10,marginBottom:10,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          Osmanağa Mah. Başçavuş sok. No: 210
          <span>
            Emintaş İş Merkezi Kadıköy/İstanbul
          </span>
        </span>
        <p className="icons">
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://www.facebook.com/halilibrahim.ciftci.946/",
                "_blank"
              )
            }
          />
          <FontAwesomeIcon
            icon={faWhatsapp}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=+905551068206",
                "_blank"
              )
            }
          />
        </p>
      </div>
    </FooterContainer>
  );
}
