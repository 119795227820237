import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { InvoiceContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import {  useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'

export default function Collection(props) {
  
  const {id} = useParams()


  const { getMainProducts } = useProductRequests();
  const {getOrder} = useOrderRequests()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover,setDiscover]=useState([])
  const [order,setOrder]=useState([])
  const [loading,setLoading]=useState(true)

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getMainProducts({ type: 1 })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {});

    }
  }, [])

  useLayoutEffect(() => {
    
    if(order.length===0){

      getOrder(id).then((response)=>{
        setOrder(response.data.data)
        setLoading(false)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])
  const columns = [
    {
      name: "",
      selector: (row, index) => <div style={{width:100}}>{index+1}</div>,
    },
    {
      name: "",
      selector: (row, index) => (
        <img onContextMenu={() => false} height={40} src={row.image} />
      ),
    },
    {
      name: "Ad",
      selector: (row) => row.title,
    },
    {
      name: "Barkod",
      selector: (row) => row.barcode,
    },
    {
      name: "Stok Kodu",
      selector: (row) => row.productCode,
    },
    {
      name: "Miktar",
      selector: (row) => row.quantity,
    },
    {
      name: "Birim",
      selector: (row) => row.unit,
    },
    {
      name: "Fiyat",
      selector: (row) => <>{row.price}</>,
    },
    {
      name: "Toplam",
      selector: (row) => <>{row.totalPrice}</>,
    },
  ];
  return (
    <>
      <Header />

      <div>
        <SlideProductContainer>
          <InvoiceContainer>
            {order == null || (order.length === 0 && loading) ? (
              <>
                <div className="loader">
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
                <div className="loader" style={{ marginTop: -100 }}>
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="head">
                  <div className="leftHead">
                    <div>
                      <span>Tarih</span>
                      <span>:</span>
                      <span>{order.order.date}</span>
                    </div>

                    <div>
                      <span>Sipariş No</span>
                      <span>:</span>
                      <span>{order.order.orderCode}</span>
                    </div>

                    <div>
                      <span>Durum</span>
                      <span>:</span>
                      <span>{order.order.status}</span>
                    </div>
                  </div>

                  <div className="rightHead">
                    <h4>
                      {JSON.parse(localStorage.getItem("customer")).title}
                    </h4>
                    <img
                      onContextMenu={() => false}
                      src={
                        order.order.image +
                        "?random=" +
                        Math.random() * Math.random()
                      }
                      height={70}
                    />
                  </div>
                </div>

                <DataTable columns={columns} data={order.items} />

                <div className="total">
                  <div className="space"></div>
                  <div>
                    <span>Toplam Tutar</span>
                    <span>:</span>
                    <>{order.order.totalPrice}</>
                  </div>
                </div>

                {/* <Button>YAZDIR</Button> */}
              </>
            )}
          </InvoiceContainer>
        </SlideProductContainer>

        <SlideProductContainer>
          <h1
            style={{
              color: "rgba(255,10,0)",
              paddingTop: 40,
              fontSize: 30,
              marginBottom: 8,
              fontWeight: "bold",
            }}
            // onClick={() => history.push("/yeni-urunler")}
          >
            Kampanyalı Ürünler
          </h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
