import React, { useState, useLayoutEffect } from "react";
import Header from "../../components/Navigation/Header";
import BottomNav from "../../components/Navigation/Bottom";
import Footer from "../../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";
import {PaymentCreateContainer} from '../../styles/Content'
import { useProductRequests } from "../../helpers/ProductRequests";

import CreatePaymentItems from "../../components/Payment";

export default function Basket(props) {
  const { getDiscover } = useProductRequests();

  const [discover, setDiscover] = useState([]);
  const [total, setTotal] = useState(null);
  const [amount, setAmount] = useState(null);

  useLayoutEffect(() => {
    if (discover.length === 0) {
      getDiscover({
        page: Math.floor(Math.random() * 15) + Math.floor(Math.random() * 20),
        count: 10,
        sort: "date",
        sortedBy: Math.floor(Math.random() * 10) % 2 == 0 ? "desc" : "asc",
      })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    }
  }, []);

  return (
    <>
      <Header />

      {total != null ? (
        <div>
          <CreatePaymentItems total={total} setTotal={setTotal} />
        </div>
      ) : (
        <PaymentCreateContainer        
        >
          <div className="payment-cont"
          >
            <div className="currency">₺</div>
            <h4>Tahsilat Tutarı Giriniz</h4>
            <input value={amount} onChange={(e)=>setAmount(e.target.value)} type={"number"} />
            <button onClick={()=>setTotal(amount)}>Sonraki Adım</button>
          </div>
        </PaymentCreateContainer>
      )}

      <BottomNav />

      <Footer />
    </>
  );
}
